body {
  background-color: grey;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.squre {
  font-size: 30px;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  height: 80px;
  width: 80px;
  line-height: 80px;
}
:hover.squre {
  color: red;
}

.conatiner {
  text-align: center;
  margin-top: 5em;
}

.board-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.status {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
  color: greenyellow;
}

.game-info {
  position: absolute;
  left: 42%;
}

.game {
  position: relative;
}

@media (max-width: 390px) {
  .game-info {
    position: absolute;
    left: 20%;
  }

  .game {
    position: relative;
  }
}
